import { useState, useMemo, useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { PLATFORMS_LIST } from "constants";
import { EmptyPlatform } from "./Platform";
import { ModalV2, Button } from "components/common";
import { ArrowDown, Warning } from "components/icons";
import { useAuthPlatforms, useCheckLimit } from "hooks/dev";

const config = (t) => ({
  TikTok: {
    title: "TikTok",
    requirements: [
      {
        title: t(
          "connect-tiktok-req-1",
          "Must be a Business or Creator profile"
        ),
      },
    ],
  },
  Instagram: {
    title: "Instagram",
    requirements: [
      {
        title: t(
          "connect-instagram-req-1",
          "Must be a Business or Creator profile"
        ),
        desc: (
          <div className="flex flex-col">
            {t(
              "connect-instagram-req-1-desc",
              "Only Instagram Business or Creator profiles are supported. Personal profiles are not supported. Switching to a Business or Creator profile is easy and only takes a few minutes"
            )}
            <a
              href="https://help.instagram.com/502981923235522"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(
                "connect-instagram-req-1-desc-link-1",
                "How to set up a business account"
              )}
            </a>
            <a
              href="https://help.instagram.com/2358103564437429"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(
                "connect-instagram-req-1-desc-link-2",
                "How to set up a creator account"
              )}
            </a>
          </div>
        ),
      },
      {
        title: t(
          "connect-instagram-req-2",
          "Must be connected to a Facebook Page"
        ),
        desc: (
          <div className="flex flex-col">
            {t(
              "connect-instagram-req-2-desc",
              "Make sure you have connected your profile to a Facebook Page, even if it's not in use"
            )}
            <a
              href="https://help.instagram.com/399237934150902"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(
                "connect-instagram-req-2-desc-link-1",
                "How to connect Instagram to a Facebook Page"
              )}
            </a>
          </div>
        ),
      },
    ],
  },
  YouTube: {
    title: "YouTube",
    requirements: [
      {
        title: t(
          "connect-youtube-req-1",
          "Google account must have be associated with a YouTube channel"
        ),
      },
    ],
  },
  X: {
    title: "X (ex Twitter)",
    requirements: [
      {
        title: t("connect-x-req-1", "Choose account"),
        desc: (
          <div className="flex flex-col">
            {t(
              "connect-x-req-1-desc",
              "Make sure you are signed in to the X (ex Twitter) account you wish to connect. You may need to switch accounts before authenticating."
            )}
            <a
              href="https://x.com/account/switch"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("connect-x-req-1-desc-link-1", "Switch accounts")}
            </a>
          </div>
        ),
      },
    ],
  },
  Facebook: {
    title: "Facebook",
    requirements: [],
  },
});

const Requirement = ({ title, desc }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div
        className={`flex items-center justify-between gap-2 ${
          desc ? "cursor-pointer" : ""
        }`}
        onClick={() => desc && setIsOpen((v) => !v)}
      >
        <div className="flex items-center gap-2">
          <Warning className="size-4 min-w-4" />
          <div>{title}</div>
        </div>
        {desc && (
          <ArrowDown
            className={`size-4 min-w-4 transition-all ${
              isOpen ? "rotate-180" : ""
            }`}
          />
        )}
      </div>
      {isOpen ? (
        <div className="text-sm border bg-white rounded-md p-2 mt-1 [&_a]:underline">
          {desc}
        </div>
      ) : null}
    </div>
  );
};

export const AuthPlatform = ({ filter = () => true }) => {
  const { t } = useTranslation();
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const { getAuthFnByPlatform, getIsLoadingByPlatform } = useAuthPlatforms({
    checkAuth: false,
  });
  const { executeFnWithCheckLimit } = useCheckLimit("ACCOUNT");

  const platformConfig = useMemo(() => {
    if (!selectedPlatform) return null;
    return config(t)[selectedPlatform];
  }, [t, selectedPlatform]);

  const onConnect = useCallback(() => {
    if (!selectedPlatform) return () => {};
    return executeFnWithCheckLimit(() =>
      getAuthFnByPlatform[selectedPlatform]()
    );
  }, [getAuthFnByPlatform, selectedPlatform]);

  const isLoading = useMemo(() => {
    if (!selectedPlatform) return false;
    return getIsLoadingByPlatform[selectedPlatform];
  }, [getIsLoadingByPlatform, selectedPlatform]);

  const generalRequirements = [
    {
      title: t("connect-general-req-1", "Account must be older than 48 hours"),
      desc: (
        <Trans
          i18nKey="connect-general-req-desc-1"
          defaults='Read more information about <a>"How to warm up new account"</a>'
          components={{
            a: (
              <a
                href="https://taisly.com/blog/how-to-warm-up-new-social-media-account"
                className="underline cursor-pointer"
                target="_blank"
              />
            ),
          }}
        />
      ),
    },
  ];

  return (
    <>
      {PLATFORMS_LIST.filter(filter).map((platform, idx) => (
        <EmptyPlatform
          key={idx}
          onClick={() => setSelectedPlatform(platform)}
          platform={platform}
        />
      ))}
      <ModalV2
        isOpen={Boolean(selectedPlatform)}
        onClose={() => setSelectedPlatform(null)}
        title={`${t("connect-platform", {
          defaultValue: "Connect {{platform}}",
          platform: platformConfig?.title,
        })}`}
        footer={
          <div className="flex w-full md:justify-end">
            <Button
              className="w-full md:w-fit"
              onClick={async () => {
                await window.localStorage.setItem(
                  "platform-auth-process",
                  "false"
                );
                onConnect();
              }}
              isLoading={isLoading}
              disabled={isLoading}
            >
              {t("connect", "Connect")}
            </Button>
          </div>
        }
      >
        <div>
          <div className="font-medium text-lg mb-2">
            {t("requirements", "Requirements")}:
          </div>
          <div className="flex flex-col gap-2">
            {(platformConfig?.requirements || []).map((el, idx) => (
              <Requirement key={idx} {...el} />
            ))}
            {generalRequirements.map((el, idx) => (
              <Requirement key={idx} {...el} />
            ))}
          </div>
        </div>
      </ModalV2>
    </>
  );
};
