import { useCallback, useEffect, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { Button, Input } from "components/common";
import { useLng, useUser } from "hooks/dev";
import { useMutation } from "@tanstack/react-query";
import { Globe, Google } from "components/icons";
import { useTranslation, Trans } from "react-i18next";
import { LocaleModal } from "components/Modal/LocaleModal";
import { emailRegex } from "constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const login = (email, callback) =>
  fetch("/api/auth/login", {
    method: "POST",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success) {
        if (res.data === "RELOAD") {
          callback();
        } else {
          return res;
        }
      } else {
        throw new Error(res.message);
      }
    });

const googleLogin = (credential, callback) =>
  fetch("/api/auth/google/login", {
    method: "POST",
    body: JSON.stringify(credential),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success) {
        callback();
      } else {
        throw new Error(res.message);
      }
    });

export const Login = () => {
  const navigate = useNavigate();
  const { language, locale } = useLng();
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const { refetch, isAuth } = useUser();

  const onSubmit = (email) => {
    if (!email?.match(emailRegex)) {
      toast.error(t("invalid-email", { defaultValue: "Invalid email" }));
      return;
    }
    return login(email, refetch);
  };

  const {
    data,
    mutate: onSendEmail,
    isLoading,
  } = useMutation({
    mutationFn: (email) => onSubmit(email),
  });

  const onChangeEmail = useCallback((value) => {
    setEmail(value);
  }, []);

  useEffect(() => {
    if (isAuth) navigate("/post");
  }, [isAuth, navigate]);

  if (data?.success)
    return (
      <div className="w-full min-h-[calc(100dvh)] flex justify-center items-center">
        <div className="w-full max-w-[400px] px-2">
          <div className="text-2xl text-main font-bold text-center mb-4">
            {t("check-your-email", { defaultValue: "Check your email" })}
          </div>
          <div className="text-xl text-main text-center">
            {t("link-was-sent-email", {
              defaultValue:
                "A sign in link has been sent to your email address",
            })}
          </div>
        </div>
      </div>
    );

  return (
    <div className="w-full min-h-[calc(100dvh)] flex justify-center items-center">
      <div className="w-full max-w-[400px] px-2">
        <div className="text-2xl font-bold text-center mb-4">
          {t("sign-in", { defaultValue: "Sign In" })}
        </div>
        <div className="w-full">
          <div className="flex justify-center w-full">
            <div className="relative w-full max-w-full">
              <button
                // onClick={() => onGoogleLogin()}
                className="w-full bg-white border border-[#dadce0] rounded h-[50px] flex items-center justify-center gap-2"
              >
                <Google className="w-[18px] h-[18px]" />
                {t("sign-in-with-google", {
                  defaultValue: "Sign in with Google",
                })}
              </button>
              <div className="absolute top-0 left-0 w-full h-[50px] opacity-0">
                <div
                  className="w-full"
                  style={{ maxWidth: "400px", maxHeight: "50px" }}
                >
                  <GoogleLogin
                    size="large"
                    onSuccess={(credentialResponse) => {
                      googleLogin(credentialResponse, refetch);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                    width={400}
                    locale={locale}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex py-4 items-center">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 text-gray-400 text-xs">
            {t("or", { defaultValue: "OR" })}
          </span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSendEmail(email);
          }}
        >
          <Input
            label={t("email", { defaultValue: "Email" })}
            placeholder="you@email.com"
            type="email"
            onChange={onChangeEmail}
            onEnter
          />
          <Button
            isLoading={isLoading}
            className="w-full h-[40px] mt-2"
            disabled={isLoading}
          >
            {t("sign-in-with-email", { defaultValue: "Sign in with Email" })}
          </Button>
        </form>
        <div className="text-sm text-center pt-2 text-gray-500">
          <Trans
            i18nKey="by-creating-tos-and-pp"
            defaults="By creating a Taisly account, you agree to our <a1>Terms of Service</a1> and <a2>Privacy Policy</a2>"
            components={{
              a1: (
                <a
                  href="https://taisly.com/terms-and-conditions"
                  className="underline text-main"
                />
              ),
              a2: (
                <a
                  href="https://taisly.com/privacy-policy"
                  className="underline text-main"
                />
              ),
            }}
          />
        </div>
        <div className="flex justify-center pt-2">
          <LocaleModal>
            <div className="text-gray-500 flex gap-2 items-center cursor-pointer">
              <Globe className="w-4 h-4" /> {language}
            </div>
          </LocaleModal>
        </div>
      </div>
    </div>
  );
};
