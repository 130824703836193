// import { useQuery } from "@tanstack/react-query";

import { PlatformIcon } from "components/icons/PlatformIcons";
import { useLocation } from "react-router-dom";

// const queryFn = () =>
//   fetch(`/api/public/groups-ads-random`).then((res) => res.json());

const ViewCount = ({ views, platform }) => {
  if (platform === "Instagram") {
    return (
      <div className="flex items-center gap-[5px] p-2">
        <svg
          aria-label="View count icon"
          class="x1lliihq x1n2onr6 x9bdzbf"
          fill="currentColor"
          height="16"
          role="img"
          viewBox="0 0 24 24"
          width="16"
        >
          <path
            d="M23.441 11.819C23.413 11.74 20.542 4 12 4S.587 11.74.559 11.819a1 1 0 0 0 1.881.677 10.282 10.282 0 0 1 19.12 0 1 1 0 0 0 1.881-.677Zm-7.124 2.368a3.359 3.359 0 0 1-1.54-.1 3.56 3.56 0 0 1-2.365-2.362 3.35 3.35 0 0 1-.103-1.542.99.99 0 0 0-1.134-1.107 5.427 5.427 0 0 0-3.733 2.34 5.5 5.5 0 0 0 8.446 6.97 5.402 5.402 0 0 0 1.536-3.09.983.983 0 0 0-1.107-1.109Z"
            fillRule="evenodd"
            fill="white"
          />
        </svg>
        <div className="text-white text-[12px] pt-[2px] font-semibold">
          {views}
        </div>
      </div>
    );
  }

  if (platform === "TikTok" || platform === "REDnote") {
    return (
      <div className="flex items-center gap-[3px] pb-[1px]">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.9">
            <path
              d="M9.525 18.0609C9.19167 18.2763 8.854 18.2886 8.512 18.0977C8.17067 17.9075 8 17.6137 8 17.2161V6.92842C8 6.53083 8.17067 6.23661 8.512 6.04577C8.854 5.85559 9.19167 5.86818 9.525 6.08354L17.675 11.2274C17.975 11.4262 18.125 11.7078 18.125 12.0722C18.125 12.4367 17.975 12.7183 17.675 12.9171L9.525 18.0609ZM10 15.4021L15.25 12.0722L10 8.74242V15.4021Z"
              fill="white"
            />
          </g>
        </svg>
        <div className="text-white text-[12px] font-semibold">{views}</div>
      </div>
    );
  }

  if (platform === "YouTube") {
    return (
      <div className="flex items-center gap-[3px] p-2">
        <div className="text-white text-[12px] [text-shadow:_0_1px_0_rgb(0_0_0_/_50%)] font-semibold">{`${views} views`}</div>
      </div>
    );
  }

  if (platform === "X") {
    return (
      <div className="flex items-center gap-[3px] p-2">
        <svg viewBox="0 0 24 24" aria-hidden="true" className="w-[18px]">
          <g>
            <path
              d="M8.75 21V3h2v18h-2zM18 21V8.5h2V21h-2zM4 21l.004-10h2L6 21H4zm9.248 0v-7h2v7h-2z"
              fill="white"
            ></path>
          </g>
        </svg>
        <div className="text-white text-[12px] font-medium">{`${views}`}</div>
      </div>
    );
  }

  return null;
};

const PlatformComponent = ({ views, platform, imageUrl }) => {
  return (
    <div className="flex justify-center">
      <div>
        {/* <div className="text-black text-[20px] font-semibold flex justify-center mt-2">
          {`${views} views`}
        </div> */}
        <div
          className="relative w-[150px] h-[150px] bg-cover bg-bottom"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <PlatformIcon
            platform={platform}
            className="absolute left-0 right-0 mx-auto top-0 bottom-0 my-auto rounded-xl shadow-md"
          />
          <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/40 to-transparent">
            {/* <div
              className="absolute  -left-3 w-[103px] border-red-500 border-[3px] h-[32px] rounded-[50%]"
              style={{
                transform: `rotate(${Math.floor(Math.random() * 21) - 10}deg)`,
              }}
            /> */}
            <ViewCount views={views} platform={platform} />
          </div>
        </div>
      </div>
    </div>
  );
};

const PlatformComponentV2 = ({ views, platform, imageUrl }) => {
  return (
    <div className="flex justify-center">
      <div>
        {/* <div className="text-black text-[20px] font-semibold flex justify-center mt-2">
          {`${views} views`}
        </div> */}
        <div
          className="relative w-[150px] h-[150px] bg-cover bg-bottom"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <PlatformIcon
            platform={platform}
            className="absolute left-0 right-0 mx-auto top-0 bottom-0 my-auto rounded-xl shadow-md w-[50px] h-[50px]"
          />
          <div className="absolute z-20 bottom-0 left-0 right-0 bg-gradient-to-t from-black/40 to-transparent">
            <div
              className="absolute  -left-3 w-[103px] border-red-500 border-[3px] h-[32px] rounded-[50%]"
              style={{
                transform: `rotate(${Math.floor(Math.random() * 21) - 10}deg)`,
              }}
            />
            <ViewCount views={views} platform={platform} />
          </div>
        </div>
      </div>
    </div>
  );
};

const generateRandomViews = (min = 78, max = 923) => {
  const randomViews = (Math.random() * (max - min) + min).toFixed(1);
  return `${randomViews}K`;
};

export const Ads = () => {
  // const { data } = useQuery({
  //   queryKey: ["GET_GROUP_ADS"],
  //   queryFn,
  // });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const title = params.get("title") || "Default Title";
  const image = params.get("image") || "#";
  const show = params.get("show") || "false";

  return (
    <div className="bg-black">
      <div className="min-h-[calc(100dvh)] flex flex-col">
        <div className="mt-[180px] py-[20px] text-black bg-white font-medium text-[30px] text-center px-14 leading-[40px]">
          {title}
        </div>
        {show === "true" && (
          <div className="w-full">
            <div className="bg-white pb-10">
              <div className="flex justify-center gap-14 pt-6">
                <PlatformComponent
                  views={generateRandomViews(101, 189)}
                  imageUrl={image}
                  platform="Instagram"
                />
                <PlatformComponent
                  views={generateRandomViews(101, 203)}
                  imageUrl={image}
                  platform="TikTok"
                />
              </div>
              <div className="flex justify-center gap-14 pt-12">
                <PlatformComponent
                  views={generateRandomViews(71, 146)}
                  imageUrl={image}
                  platform="YouTube"
                />
                <PlatformComponentV2
                  views={generateRandomViews(890, 989)}
                  imageUrl={image}
                  platform="REDnote"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
